import { bookingSystemVersion } from './booking-configuration/booking-configuration-version';
import { obfuscateEmail, obfuscateNumber } from './obfuscate';

export { getDateString } from './booking-configuration/booking-configuration-dates';

export * from './razorpay';

export enum TentTypeV1 {
  DORM_SINGLE = 'dormSingle',
  DORM_BUNK = 'dormBunk',
  PRIVATE_GROUP_COTTAGE = 'privateGroupCottage',
  TWIN_ROOM = 'twinRoom',
  FAMILY_ROOM = 'familyRoom',
}

export enum TentTypeV1Prices {
  DORM_SINGLE = 2200, // per person
  DORM_BUNK = 1500, // per person
  TWIN_ROOM = 4025, // per person
  FAMILY_ROOM = 3750, // per person
  PRIVATE_GROUP_COTTAGE = 2200, // per person
}

export enum TentTypeV2 {
  PREMIUM_TWIN_COTTAGE = 'premiumTwinCottage',
  MUDHOUSE = 'mudhouse',
  MUDHOUSE_SUITE = 'mudhouseSuite',
}

export enum TentTypeV2Prices {
  PREMIUM_TWIN_COTTAGE = 6875, // per person
  MUDHOUSE = 51000, // per tent
  MUDHOUSE_SUITE = 64000, // per tent
}

export const tentTypeToName: { [K in TentTypeV1 | TentTypeV2]: string } = {
  [TentTypeV1.TWIN_ROOM]: 'Twin Bed Cottage',
  [TentTypeV2.PREMIUM_TWIN_COTTAGE]: 'Premium Twin Cottage',
  [TentTypeV2.MUDHOUSE]: 'Traditional Mudhouse',
  [TentTypeV2.MUDHOUSE_SUITE]: 'Premium Mudhouse Suite',
  [TentTypeV1.PRIVATE_GROUP_COTTAGE]: 'Private Group Cottage',
  [TentTypeV1.FAMILY_ROOM]: 'Family Cottage',
  [TentTypeV1.DORM_SINGLE]: 'Dorm Single Bed',
  [TentTypeV1.DORM_BUNK]: 'Dorm Bunk Bed',
} as const;

export const roomsToEnumeratedString = (rooms: BookingItem[], join?: string) =>
  rooms
    .reduce((acc, [type, count]) => {
      if (count === 0) {
        return acc;
      }
      acc.push(`${count} x ${tentTypeToName[type]}`);
      return acc;
    }, [] as string[])
    .join(join ?? ' | ');

export type PaymentProvider = 'paypal' | 'razorpay';

export type Gender = 'male' | 'female' | 'non-binary' | 'prefer-not-to-say' | 'none';

export type PaymentStatus = 'pending' | 'failed' | 'success' | 'not-started';

export type Payment =
  | {
      /**
       * The unique identifier for the payment made by customer for this booking.
       */
      id: string;
      /**
       * The payment provider used for this booking.
       */
      paymentProvider: PaymentProvider;
      /**
       * The current status of the payment in this booking.
       */
      status: 'pending' | 'failed' | 'success';
    }
  | {
      status: 'not-started';
    };

export interface Customer {
  /**
   * Unique identifier for the primary contact.
   */
  id: string;
  /**
   * Name of the customer who made the booking.
   */
  name: string;
  /**
   * Phone number of the customer who made the booking.
   */
  phone: string;
  /**
   * Email of the customer who made the booking.
   */
  email: string;
  /**
   * A boolean flag signalling if customer has accepted the t&c.
   */
  acceptedConditions: boolean;
  /**
   * The ID of the booking which belongs to this customer.
   */
  bookingId: BookingId;
  /**
   * A boolean flag signalling if customer has a verified email address.
   */
  isEmailAddressVerified: boolean;
}

export type CustomerRequest = Omit<Customer, 'bookingId' | 'isEmailAddressVerified'>;

export const Sources = ['website', 'web-inquiry', 'phone', 'agent'] as const;
export interface BookingAdditionalInfo {
  id: BookingAdditionalInfoId;
  bookingId: BookingId;
  notes: string;
  transport: {
    pickup: { requiresPickup: boolean; location: string };
    drop: { requiresDrop: boolean; location: string };
  };
  agent: string;
  source: (typeof Sources)[number];
  paymentAdditionalInfo: {
    expected: number;
    received: number;
    balancePayableBy: string;
  };
}

export interface InvoiceData {
  bookingId: string;
  dateOfBooking: string;
  name: string;
  arrivalDate: string;
  country: string;
  departureDate: string;
  phone: string;
  pickup: string;
  email: string;
  dropoff: string;
  travelDetails: string;
  roomTypes: string;
  numberOfRooms: string;
  experiencesIncluded: string;
  numberOfBeds: number;
  adults: number;
  children: number;
  additionalInstruction: string;
  property: string;
  payable: number;
  received: number;
  paymentRef: string;
  balanceAmount: string;
  paymentMethod: string;
}

/**
 * The central interface which defines the structure of bookings in camp67.
 */
export interface Booking {
  /**
   * A unique identifier for a particular booking.
   */
  id: BookingId;
  /**
   * A reference to this booking and its storage in an external system (Nivaas).
   */
  externalId?: string;
  /**
   * The primary contact information of a customer who has made a particular booking.
   */
  primaryContact: Customer;
  /**
   * The reservation information of a particular booking.
   */
  reservation: {
    /**
     * The accommodation type used in this reservation.
     */
    rooms: BookingItem[];
    /**
     * The check in date / start date of this booking.
     */
    startDate: string;
    /**
     * The check out date / end date of this booking.
     */
    endDate: string;
    /**
     * The guest information of this booking.
     */
    guests: {
      /**
       * The number of adults in this booking.
       */
      adult: number;
      /**
       * The number of children (under the age of 18) in this booking.
       */
      children: number;
      /**
       * The list of details for each guest.
       */
      details: GuestDetail[];
    };
  };
  /**
   * The payment information of a particular booking - specifically,
   * the information provided upon the initiation of a payment, success
   * of a payment or failure of a payment. NOT the customer's data.
   */
  payment: Payment;

  /**
   * Store the price charged for this booking.
   */
  price: {
    /**
     * The total price charged for this booking.
     */
    total: number;
    /**
     * The price charged for the accommodation.
     */
    accommodationCost: number[];
    /**
     * The price charged for the bathing services.
     */
    bathingCost: number;
    /**
     * The number of nights this booking is for.
     */
    nightsCount: number;
    /**
     * The number of nights which overlap with bathing dates.
     */
    nightsBathingCount: number;
  };
  /**
   * The ID of the room allocated for this booking.
   */
  rooms: RoomId[];
  /**
   * When the booking was created for bookkeeping purposes.
   */
  createdAt: number;
  /**
   * Boolean flag used to indicate if the booking has successfully been allocated.
   * In certain cases, we have situations where a booking that may have been deleted has just been
   * paid for by a customer - due to delays or some kind of unknown issue. In this scenario, we need to
   * ensure that the booking is not actually deleted, but also, indicate that the booking is not allocated.
   * Then we can later search for bookings that have a success payment but are not allocated and allocate them.
   */
  isAllocated: boolean;
}

export type IncompleteBookingEnvelope = {
  id: IncompleteBookingId;
  bookingId: BookingId;
  expiresAt: number;
};

export interface GuestDetail {
  name: string;
  age: number;
  gender: Gender;
  /**
   * Unique identifier for this guest
   */
  id: string;
}

export type BookingItem = [TentTypeV1 | TentTypeV2, number];

export const TENT_TYPE_V1_TO_NIVAAS_SLUG = {
  [TentTypeV1.TWIN_ROOM]: 'twin-bed-cottage',
  [TentTypeV1.FAMILY_ROOM]: 'family-cottage',
  [TentTypeV1.DORM_SINGLE]: 'dorm-single',
  [TentTypeV1.DORM_BUNK]: 'dorm-bunk',
  [TentTypeV1.PRIVATE_GROUP_COTTAGE]: 'private-group-cottage',
} as const;

export interface BookingRequest {
  primaryContact: CustomerRequest;
  reservation: {
    rooms: BookingItem[];
    startDate: string;
    endDate: string;
    guests: {
      adult: number;
      children: number;
      details: GuestDetail[];
    };
  };
  // For specific/optional tweaks in TentTypeV2, we can use this price object
  // here so that costing that is done custom can be provided as an override.
  // Uses the same model as the usual one.
  priceOverride?: {
    // Total including GST
    total: number;
    // Total just from accommodation
    accommodationCost: number[];
  };
}

export interface BookingSimple {
  id: BookingId;
  primaryContact: {
    id: string;
    name: string;
    phone: string;
    email: string;
  };
  reservation: {
    rooms: BookingItem[];
    startDate: string;
    endDate: string;
    guests: {
      count: number;
      details: GuestDetail[];
    };
  };
  payment: {
    status: Payment['status'];
  };
}

export interface InquiryInput {
  name: string;
  email: string;
  phone: string;
  inquiry: string;
}

export interface Inquiry {
  id: InquiryId;
  name: string;
  email: string;
  phone: string;
  inquiry: string;
}

export const toBookingSimple = (booking: Booking): BookingSimple => {
  return {
    id: btoa(booking.id) as BookingId,
    primaryContact: {
      id: booking.primaryContact.id,
      name: booking.primaryContact.name,
      phone: obfuscateNumber(booking.primaryContact.phone),
      email: obfuscateEmail(booking.primaryContact.email),
    },
    reservation: {
      rooms: booking.reservation.rooms,
      startDate: booking.reservation.startDate,
      endDate: booking.reservation.endDate,
      guests: {
        count: booking.reservation.guests.adult + booking.reservation.guests.children,
        details: booking.reservation.guests.details,
      },
    },
    payment: {
      status: booking.payment.status,
    },
  };
};

export interface Room {
  id: RoomId;
  type: TentTypeV1 | TentTypeV2;
}

export interface RoomSlot {
  id: RoomSlotId;
}

export interface RoomAllocation {
  id: RoomAllocationId;
  takenBy: BookingId | null;
}

export interface Subscription {
  subscribedAt: string;
  email: string;
}

export type CustomerId = string & { __customerId: never };
export const toCustomerId = (email: string) => `customer#${email}` as CustomerId;

export type SubscriptionId = string & { __subscriptionId: never };
export const toSubscriptionId = (email: string) =>
  `subscription#${email}` as SubscriptionId;

export type PaymentEnvelopeId = string & { __paymentEnvelopeId: never };
export const toPaymentEnvelopeId = (provider: PaymentProvider, id: string) =>
  `payment-envelope#${provider}#${id}` as PaymentEnvelopeId;

export type BookingAdditionalInfoId = string & { __bookingId: never };
export const toBookingAdditionalInfoId = (id: string) =>
  `bookingAdditionalInfo#${bookingSystemVersion}#${id}` as BookingAdditionalInfoId;

export type BookingId = string & { __bookingId: never };
export const toBookingId = (id: string) =>
  `booking#${bookingSystemVersion}#${id}` as BookingId;

export type RoomId = string & { __roomId: never };
export const toRoomId = (type: TentTypeV1 | TentTypeV2, id: number) =>
  `room#${bookingSystemVersion}#${type}#${id}` as RoomId;
export const toRoomTypeFromId = (roomId: RoomId) =>
  roomId.split('#')[2] as TentTypeV1 | TentTypeV2;
export const toRoomIdFromSlotId = (slotId: RoomSlotId) => {
  const [_, __, ___, type, id] = slotId.split('#');
  return toRoomId(type as TentTypeV1 | TentTypeV2, parseInt(id, 10));
};

export type RoomSlotId = string & { __roomSlotId: never };
export const toRoomSlotId = (type: TentTypeV1 | TentTypeV2, id: number, date: string) =>
  `slot#${bookingSystemVersion}#${date}#${type}#${id}` as RoomSlotId;
export const toRoomSlotIdFromRoomId = (roomId: RoomId, date: string) => {
  const [_, __, type, id] = roomId.split('#');
  return toRoomSlotId(type as TentTypeV1 | TentTypeV2, parseInt(id, 10), date);
};

export type RoomSlotPrefix = string & { __roomSlotPrefix: never };
export const toRoomSlotPrefix = (type: TentTypeV1 | TentTypeV2, date: string) =>
  `slot#${bookingSystemVersion}#${date}#${type}#` as RoomSlotPrefix;
export const toRoomSlotPrefixDateOnly = (date: string) =>
  `slot#${bookingSystemVersion}#${date}` as RoomSlotPrefix;

export type RoomAllocationId = string & { __roomAllocationId: never };
export const toRoomAllocationId = (
  type: TentTypeV1 | TentTypeV2,
  id: number,
  date: string,
) => `allocation#${bookingSystemVersion}#${type}#${id}#${date}` as RoomAllocationId;
export const toRoomAllocationIdFromRoomSlotId = (roomSlotId: RoomSlotId) => {
  const [_, __, date, type, id] = roomSlotId.split('#');

  const allocationId = toRoomAllocationId(
    type as TentTypeV1,
    parseInt(id, 10),
    date,
  ) as RoomAllocationId;

  if (roomSlotId.endsWith('#')) {
    return `${allocationId}#` as RoomAllocationId;
  }

  return allocationId;
};

export type IncompleteBookingId = string & { __incompleteBookingId: never };
export const toIncompleteBookingId = (id: string) =>
  `incomplete#${bookingSystemVersion}#${id}` as IncompleteBookingId;

export type GuestType = 'adult' | 'children';

export const isBooking = (booking: any): booking is Booking => {
  return (
    booking &&
    typeof booking === 'object' &&
    'primaryContact' in booking &&
    'reservation' in booking &&
    'payment' in booking &&
    Array.isArray(booking.rooms)
  );
};

export type InquiryId = string & { __inquiryId: never };
export const toInquiryId = (id: string) => `inquiry#${id}` as InquiryId;
